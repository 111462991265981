@use 'sass:map'
@use 'sass:selector'

$primary: #000

// Configuration
@import "../node_modules/bootstrap/scss/functions"
@import "../node_modules/bootstrap/scss/variables"
@import "../node_modules/bootstrap/scss/mixins"

$font-family-sans-serif: Play
$theme-colors: map.merge($theme-colors, ('black': #000, 'white': #fff))
@import "../node_modules/bootstrap/scss/utilities"

// Layout & components
@import "../node_modules/bootstrap/scss/root"
@import "../node_modules/bootstrap/scss/reboot"
@import "../node_modules/bootstrap/scss/type"
@import "../node_modules/bootstrap/scss/images"
@import "../node_modules/bootstrap/scss/containers"
@import "../node_modules/bootstrap/scss/grid"
@import "../node_modules/bootstrap/scss/tables"
@import "../node_modules/bootstrap/scss/forms"
@import "../node_modules/bootstrap/scss/buttons"
@import "../node_modules/bootstrap/scss/transitions"
@import "../node_modules/bootstrap/scss/dropdown"
@import "../node_modules/bootstrap/scss/button-group"
@import "../node_modules/bootstrap/scss/nav"
@import "../node_modules/bootstrap/scss/navbar"
@import "../node_modules/bootstrap/scss/card"
@import "../node_modules/bootstrap/scss/accordion"
@import "../node_modules/bootstrap/scss/breadcrumb"
@import "../node_modules/bootstrap/scss/pagination"
@import "../node_modules/bootstrap/scss/badge"
@import "../node_modules/bootstrap/scss/alert"
@import "../node_modules/bootstrap/scss/progress"
@import "../node_modules/bootstrap/scss/list-group"
@import "../node_modules/bootstrap/scss/close"
@import "../node_modules/bootstrap/scss/toasts"
@import "../node_modules/bootstrap/scss/modal"
@import "../node_modules/bootstrap/scss/tooltip"
@import "../node_modules/bootstrap/scss/popover"
@import "../node_modules/bootstrap/scss/carousel"
@import "../node_modules/bootstrap/scss/spinners"
@import "../node_modules/bootstrap/scss/offcanvas"

// Helpers
@import "../node_modules/bootstrap/scss/helpers"

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api"

// Bourbon
@import '../node_modules/bourbon/core/bourbon'

@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap')

// Hier kann Css geschreiben werden.

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%))
  // For Google Chrome
  &::-webkit-scrollbar
    width: $size
    height: $size
  &::-webkit-scrollbar-thumb
    border-radius: 4px
    background: $foreground-color
  &::-webkit-scrollbar-track
    background: $background-color

#root
  width: fit-content
  min-width: 100%

.cardimg
  width: 100%
  aspect-ratio: 1/1
  object-position: center center
  object-fit: contain

.disabled
  +size(100%)
  +position(absolute, 0 null null 0)
  background-color: rgba($color: #000000, $alpha: .5)

.no-border
  border: none

// USER BUTTON
#user-button
  +position(fixed, 50px 145px null null)
  z-index: 3
  isolation: isolate

  +media-breakpoint-down(lg)
    display: none

  .button-wrapper
    position: absolute
    inset: 0
    +size(60px)
    background-color: #6c757d
    clip-path: circle(50% at 50%)

    img
      +position(absolute, 0)
      +size(100%)
      object-fit: cover
      object-position: center

    svg
      display: block
      +position(absolute, 0)
      +size(100%)
      +padding(10px)
      cursor: pointer

      path
        fill: transparent
        transition: fill .3s ease

      &:hover, &:focus
        animation: rotate .7s ease-in-out
        background: #0005
        transition: background .3s ease-out

        path
          fill: #fff
          transition: fill .3s ease-out

#user-menu
  +position(absolute, 0 -30px 0 null)
  z-index: -1
  height: 60px
  transform-origin: right center
  transform: scaleX(0)
  transition: transform 350ms ease-in

  &[aria-expanded="true"]
    transform: none
    transition-timing-function: ease-out

  #logout
    border-bottom-left-radius: 30px
    border-top-left-radius: 30px
    background-color: $red

    &:hover
      background-color: $red

  button
    border: none
    border-right: 3px #eee solid
    color: #000
    font-weight: bold
    background-color: #fff
    height: 100%

    &:focus
      box-shadow: none

    &:hover
      background-color: #000
      color: #fff


#auto-logout
  +position(fixed, 0 0 null)
  z-index: 9999
  pointer-events: none
  opacity: 0

  &[data-show="true"]
    transition: opacity .8s ease-out
    opacity: 1

  progress
    +position(absolute, 0)
    +size(100%, 2rem)
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    border: none

    &::-webkit-progress-bar
      background-color: rgba($gray-300, .3)
      backdrop-filter: blur(8px)

    @mixin auto-logout__progress-value
      background-image: linear-gradient(to right, #000, #777 50%, #ff6f61)
      background-size: 100vw 100%

    &::-webkit-progress-value
      +auto-logout__progress-value

    &::-moz-progress-bar
      +auto-logout__progress-value

  p
    +position(absolute, 0)
    +size(100%, 2rem)
    line-height: 2rem
    text-align: center
    margin: 0
    mix-blend-mode: difference

.login-selection
  &__wrapper
    +size(100vw, 100%)
    min-height: 100vh
    position: relative
    isolation: isolate
    overflow-x: hidden
    display: flex
    flex-direction: column
    justify-content: center

  &__inner-wrapper
    width: 100%

  &__inner
    display: flex
    transition: transform .75s ease-in-out

    & > *
      display: inline-flex
      justify-content: center
      align-items: center
      width: 50%
      transform: scale(.75)
      flex-shrink: 0
      transition: transform .5s ease-out

      &.current
        transform: scale(1)

  &__indicators
    width: 100%
    display: flex
    justify-content: center
    column-gap: .5em

  &__indicator
    display: inline-block
    +size(2em, .35em)
    background: $gray-400
    flex-shrink: 1
    cursor: pointer
    position: relative

    &.current
      background: $gray-600

    &:hover, &:focus-visible
      background: $gray-500

    &[data-letter]:not([data-letter="-"])
      &::after
        content: attr(data-letter)
        +position(absolute, null 0 0)
        transform: translateY(100%)
        font-size: .7em
        color: $gray-300
        text-align: center

      &:is(:hover, :focus-visible)::after
        color: $gray-400

      &.current::after
        color: $gray-500

  &__prev, &__next
    +position(absolute, 50% null null null)
    z-index: 10
    transform: translateY(-50%)
    background: rgba($gray-700, .7)
    color: #fff
    font-size: 3em
    cursor: pointer
    padding: 2rem 0
    box-sizing: content-box
    backdrop-filter: blur(8px)
    box-shadow: 0 2px 8px #0008

  &__prev
    left: -.3rem
    border-radius: 0 9999vmax 9999vmax 0
    padding-right: .75rem
    padding-left: .3rem

    &:hover, &:focus-visible
      animation: prev 2s linear infinite

  &__next
    right: -.3rem
    border-radius: 9999vmax 0 0 9999vmax
    padding-right: .3rem
    padding-left: .75rem

    &:hover, &:focus-visible
      animation: next 2s linear infinite


@keyframes rotate
  from
    transform: rotate(0)
  to
    transform: rotate(45deg)

@keyframes prev
  0%, 20%
    transform: translate(0, -50%)
  10%
    transform: translate(.3rem, -50%)

@keyframes next
  0%, 20%
    transform: translate(0, -50%)
  10%
    transform: translate(-.3rem, -50%)

.sticky
  position: sticky
  position: -webkit-sticky
  top: 0
  z-index: 1
  background-color: #fff

.dashboard-header
  padding-top: 15px
  @media (min-width: 992px)
    padding: 50px 0 0 85px
  font-size: 40px
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: normal
  color: #000

.beverage-filters
  padding: 0 0 10px 0
  display: flex
  @media (min-width: 992px)
    padding: 0 0 10px 70px

  .filter-button
    text-align: center
    margin: 25px 0 0
    padding: 10px 0 10px 0
    width: 100%
    border-radius: 26.5px
    background-color: #fff
    border-color: #d6d6d6
    color: #000
    font-size: 18px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: normal
    letter-spacing: normal

    &:focus
      box-shadow: none
      border: none
      background-color: #000
      color: #fff

    &:hover
      box-shadow: none
      background-color: #000
      color: #fff


.beverage-card
  position: relative
  margin: 30px 0 0 0
  border-radius: 45px
  background-color: #fff

  .card-body
    text-align: center

    .cardimg
      padding: 0 30% 0 30%

    .title
      position: absolute
      left: 0
      right: 0
      max-width: 160px
      margin-top: 20px
      margin-right: auto
      margin-left: auto
      text-align: center
      font-size: 22px
      font-weight: bold
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      color: #000

    .price
      margin-top: 100px
      font-size: 18px
      font-weight: bold
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      color: #000

    .volume
      font-size: 18px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: normal
      letter-spacing: normal
      color: #949494

      .text
        font-size: 18px
        font-weight: bold
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        color: #fff

    .stock
      position: absolute
      right: 15%
      top: 0
      height: 55px
      width: 55px
      border: 1px solid #979797
      border-radius: 100%
      text-align: center
      @media (min-width: 768px)
        height: 35px
        width: 35px
        .text
          font-size: 14px !important
          top: 7px !important
      @media (min-width: 1400px)
        height: 55px
        width: 55px
        .text
          font-size: 18px !important
          top: 15px !important

      &[data-active='true']
        background: #198754
        animation-name: confirmation
        animation-duration: 3s

      @keyframes confirmation
        15%
          background: #198754
          box-shadow: 0 0 10px 1px #198754
        100%
          background: white


      .text
        position: absolute
        margin-left: auto
        margin-right: auto
        left: 0
        right: 0
        top: 16px
        text-align: center
        font-size: 18px
        font-weight: bold
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        color: #000

.buy-button
  text-align: center
  margin: 25px 0 0
  padding: 3% 0 3% 0
  border-radius: 30px
  background-color: #000
  border-color: #000
  width: 50%

  &:disabled
    background-color: #000
    border-color: #000

  &:focus
    box-shadow: none
    background-color: #000
    border-color: #000
    color: #fff

  &:hover
    box-shadow: none
    background-color: #fff
    border-color: #000
    color: #000

  &:active
    box-shadow: none
    background-color: #fff
    border-color: #000
    color: #fff

    .text
      color: #000


.notBuy-Button
  text-align: center
  margin: 25px 0 0
  padding: 3% 0 3% 0
  border-radius: 30px
  background-color: #fff
  border-color: $red
  width: 50%
  color: $red

  &:disabled
    background-color: #fff
    border-color: #fff

  &:focus
    box-shadow: none
    background-color: #fff
    border-color: #fff
    color: $red

  &:hover
    box-shadow: none
    background-color: $red
    border-color: $red
    color: #fff

  &:active
    box-shadow: none
    background-color: #fff
    border-color: $red
    color: $red


.buyModal-Body
  text-align: center
  font-size: 40px
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: normal

  .buyModal-headline
    margin-bottom: 40px
    margin-top: 50px

  .buyModal-content
    margin-bottom: 50px
    margin-top: 20px


.login
  +media-breakpoint-up(lg)
    background: url('img/fernando-andrade-NfaZjll9-8g-unsplash.jpg') right center / 50% auto no-repeat
    padding-inline-end: 50%

    &[data-cucumber="true"]
      background-image: url('img/leighann-blackwood-K1-KRD8PrGI-unsplash.jpg')

  h2
    font-size: 4rem
    max-width: 8ch
    white-space: nowrap
    text-overflow: ellipsis
    overflow-x: hidden

  &-circles
    display: flex
    justify-content: center
    gap: .5em

    span
      display: block
      width: .95rem
      aspect-ratio: 1
      border-radius: 50%
      border: 1px solid $white

    &[data-filled="1"] span:nth-child(1),
    &[data-filled="2"] span:nth-child(-n+2),
    &[data-filled="3"] span:nth-child(-n+3),
    &[data-filled="4"] span:nth-child(-n+4)
      background: $white

  &-buttons
    display: grid
    grid-template: repeat(4, 1fr) / repeat(3, 1fr)
    justify-content: center
    align-items: center
    justify-items: center
    gap: 1em
    width: auto

  &-button
    width: 4.75rem
    aspect-ratio: 1

    &:focus, &:active
      outline: none
      box-shadow: none

    &.login-button-number
      border: 1px solid $white
      font-size: 1.8rem
      border-radius: 999vmax

      &:hover, &:focus-visible
        background: $white !important
        color: $black !important

    &.login-button-cancel
      font-size: .9rem

      &:hover, &:focus-visible
        text-decoration: underline !important

    &.login-button-remove
      font-size: 2.25rem
      color: rgb(250, 114, 104)

      &:hover, &:focus-visible
        color: rgb(220, 80, 80)

  &-error
    background: $danger
    font-size: .8rem
    padding: .25rem
    max-width: 20ch
    isolation: isolate
    margin-left: 1em

    &::before
      content: ""
      +position(absolute, 50% null null 0)
      +triangle(left, 1em, 1em, $danger)
      transform: translate(-100%, -50%)

.statistic-card
  color: #fff
  position: relative
  margin: 30px 0 0 0

  .card-body
    text-align: center
    min-height: 675px
    padding: 1rem 1rem
    display: flex

    .card-content
      top: 50%
      border-radius: 45px
      background-color: #000
      width: 100%
      padding: 35px

      .head
        padding: 0
        font-size: 40px
        font-weight: bold
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        text-decoration: underline

      .body
        margin-top: 30px
        padding: 0
        font-size: 21px
        font-weight: bold
        font-stretch: normal
        font-style: normal
        line-height: normal
        letter-spacing: normal
        overflow-y: auto
        overflow-x: hidden
        max-height: 450px
        scroll-behavior: smooth
        @include scrollbars(5px, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.05))

      .body.row.col.first
        text-align: start


.filter-range::before
  content: attr(value)
  color: black
  padding-right: 10px
  display: inline-block
  width: 3ch




